export const VODAFONE_CASH_METHOD = { value: 'vodafone_cash', name: 'فودافون كاش' };
export const ORANGE_CASH_METHOD = { value: 'orange_cash', name: 'اورنج كاش (امان)' };
export const ETISALAT_CASH_METHOD = { value: 'etisalat_cash', name: 'اتصالات كاش' };
export const WE_PAY_METHOD = { value: 'we_pay', name: 'وي باي (امان)' };
export const EGP_BANK_TRANSFER_METHOD = { value: 'egp_bank_transfer', name: 'تحويل بنكي' };
export const INTL_BANK_TRANSFER_METHOD = {
  value: 'international_bank_transfer',
  name: 'تحويل بنك دولي',
};
export const PAYONEER_TRANSFER_METHOD = { value: 'payoneer', name: 'تحويل عن طريق بايونير' };
export const PRE_ORDER_REQUEST_LOCKING_METHOD = {
  value: 'PreOrderWithdrawal',
  name: 'طلب مسبق بالجملة (مبلغ محجوز)',
};
export const PRE_ORDER_REQUEST_REFUND_METHOD = {
  value: 'PreOrderRefund',
  name: 'طلب مسبق بالجملة (مبلغ مسترجع للمحفظة)',
};
export const WALLET_TOPUP_PAYMENT_REQUEST_TYPE = {
  value: 'BankCashIn',
  name: 'مبلغ تم إضافته لمحفظتك',
};
export const CASH_INCENTIVE_PAYMENT_REQUEST_TYPE = {
  value: 'cash',
  name: 'مبلغ تم إضافته لمحفظتك',
};

export const DEFAULT_WITHDRAWAL_METHODS = [
  VODAFONE_CASH_METHOD,
  ORANGE_CASH_METHOD,
  ETISALAT_CASH_METHOD,
  WE_PAY_METHOD,
  EGP_BANK_TRANSFER_METHOD,
];

export const PAYMENT_REQUEST_TYPES = [
  ...DEFAULT_WITHDRAWAL_METHODS,
  INTL_BANK_TRANSFER_METHOD,
  PAYONEER_TRANSFER_METHOD,
  PRE_ORDER_REQUEST_LOCKING_METHOD,
  PRE_ORDER_REQUEST_REFUND_METHOD,
  WALLET_TOPUP_PAYMENT_REQUEST_TYPE,
];
