import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.scss'],
  standalone: true,
})
export class SuccessMessageComponent implements OnInit {
  @Input() message: string;

  @Output() closeEvent = new EventEmitter<string>();

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  ngOnInit(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'opt_in_success_page_load' });
  }

  onClose() {
    this.closeEvent.emit();
  }
}
