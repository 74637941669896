import { OtpCheckModel } from '../payment/otp-check.model';
import { WithdrawalMethods } from '../payment/payment-methods.model';

export interface WalletTransactionHistoryModel {
  transactions: WalletTransactionModel[];
  count: number;
}

export interface WalletTransactionModel {
  amount: number;
  category: string;
  currency: string;
  details: {
    type: string;
    typeId: string;
    toStatus?: string;
    fromStatus?: string;
  };
  notes: string;
  transactionDate: string;
}

export interface WalletTransactionRequestFilterModel {
  page: number;
  pageSize: number;
  currency?: string | null;
  type?: WalletTransactionType;
  toDate?: string;
  fromDate?: string;
}

export enum WalletTransactionType {
  income = 'income',
  expense = 'expense',
}

export interface ChangeWalletPasswordModel {
  currentWalletPassword: string;
  newWalletPassword: string;
  confirmWalletPassword: string;
}

export interface ResetWalletPasswordModel {
  data: { password: string; confirmPassword: string };
  id: string;
  resetToken: string;
}

export interface WalletModel {
  currency: string;
  eligibleAmount: number;
  expectedAmount: number;
  ordersCountForExpectedAmount: number;
  amountsOwedToTaager: number;
}

/**
 * @deprecated Use CreateSecuredWithdrawalRequestModel to require OTP during withdrawal request creation
 */
export interface CreateWithdrawalRequestModel {
  currency: string;
  amount: number;
  paymentMethod: WithdrawalMethods;
  phoneNum: string;
  otpVerificationMode: 'email' | 'sms';
}

export interface CreateSecuredWithdrawalRequestModel {
  currency: string;
  amount: number;
  paymentMethod: string;
  phoneNum: string;
  otpCheckModel: OtpCheckModel;
}

export interface GetWithdrawalsListRequestModel {
  page: number;
  pageSize: number;
  status?: string[];
  currency?: string;
  fromDate?: string;
  toDate?: string;
}

export interface WithdrawalsListModel {
  count: number;
  withdrawals: WithdrawalTransactionModel[];
}

export interface WithdrawalTransactionModel {
  id: string;
  taagerId: string;
  fullName: string;
  amount: number;
  currency: string;
  paymentMethod: string;
  phoneNum: string;
  internationalBankTransfer: {
    beneficiaryBank: {
      name: string;
      address: string;
      swiftNumber: string;
    };
    beneficiary: {
      name: string;
      address: string;
      contactNumber: string;
    };
    beneficiaryAccount: {
      iban: string;
      currency: string;
    };
  };
  paypal: {
    beneficiary: {
      email: string;
    };
  };
  status: string;
  createdAt: string;
  rejectReason?: string;
}
